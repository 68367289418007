import React, { useState } from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {
  Breadcrumbs,
  Button,
  FlipCard,
  Hero,
  IconBox,
  SecondaryHeader,
  SectionHeader,
} from "../components/common";
import Item from "../components/item";

import Subscription from "../components/subscription";
import CollectionItem from "../components/collectionItem";
import { generateHeading } from "../helpers";

import "./collection.css";

function CollectionPage({
  pageContext: { data: collection, collections },
  location,
}) {
  const [state, setState] = useState({
    itemsToShow: 6,
    itemsToLoadMore: 6,
  });

  if (!collection || !collections?.length) {
    return null;
  }

  const title = generateHeading(
    collection.Headline,
    collection?.products?.length
  );
  const description =
    collection.SubHeadline ||
    "Gradients are the new colors. Carefully curated multi-layered linear, radial, conic and mesh gradients to make your project shine, expressive and exclusive.";

  return (
    <Layout>
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title={collection?.SEOTitle || title}
        description={collection?.MetaDescription || description}
        image={
          collection.products?.[0]?.FeaturedImage?.data?.attributes?.localFile
            ?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
        }
        metaKeywords={collection?.MetaKeywords}
        metaRobots={collection?.MetaRobots}
        path={collection?.CanonicalURL || location?.pathname}
      />

      <section className="dev-vg-hero-section md:mt-24 md:mb-20 my-12">
        <Hero
          subTitle={collection.Eyebrow || "Curated Collections"}
          title={title}
          description={description}
        >
          <Breadcrumbs
            lists={[
              {
                title: "Home",
                to: "/",
              },
              {
                title: "Collections",
                to: "/collections",
              },
              {
                title: collection?.Name || "Gradient Backgrounds",
                to: `/collection/${collection?.URL || ""}`,
              },
            ]}
          />
        </Hero>
      </section>
      <section className="md:mb-40 mb-16">
        {collection.products?.length ? (
          <>
            {/* <div className="flex items-center justify-end mb-8">
              {false && <div>Show Sale Products Only</div>}
              <Link to="/collections" className="flex-inline items-center">
                View All <i className="vg-arrow-right ml-2" />
              </Link>
            </div> */}
            <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 md:gap-x-8 gap-x-6 md:gap-y-16 gap-y-8">
              {collection?.products
                ?.slice(0, state.itemsToShow)
                ?.map((product) => (
                  <Item
                    key={product.id}
                    title={product.Name}
                    hidePricing
                    price={product.Price}
                    salePrice={product.SalePrice}
                    featuredImage={
                      product.FeaturedImage.data.attributes.localFile
                    }
                    url={product.URL}
                  />
                ))}
            </div>
            {collection?.products?.length > state.itemsToShow && (
              <div className="md:mt-16 mt-12 flex items-center justify-center">
                <Button
                  text="Load More Products"
                  iconRotate={90}
                  icon="vg-arrow-right"
                  onClick={() => {
                    setState({
                      ...state,
                      itemsToShow: state.itemsToShow + state.itemsToLoadMore,
                    });
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div className="flex items-center justify-center font-stylish text-lg">
            Sorry no item found in this collection, but here&apos;s more listed
            below.
          </div>
        )}
      </section>
      <section className="md:mb-32 mb-24">
        <div className="dev-vg-g-product-section__head mb-12">
          <SectionHeader
            title="Related Collections"
            description="Categories have too many products to choose from. That’s why we gathered our design assets into niche collections. Go grab your desired asset now, it’s easier than ever."
          />
        </div>
        <div className="dev-vg-g-product-section__items grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-8 md:gap-y-16 gap-y-12">
          {collections?.map((collection, index) => (
            <CollectionItem key={index} collection={collection} />
          ))}
        </div>
      </section>
      <section className="md:mb-40 mb-24">
        <div className="md:mb-16 mb-20">
          <SectionHeader
            art
            title={
              <>
                Explore
                <br />
                Without Any Boundaries
              </>
            }
            description="We aim to give more, to ensure you grow big. Get the best perks for your best money. Use our products unlimited times in unlimited projects by unlimited ways."
          />
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 md:gap-8 gap-12">
          <IconBox icon="vg-infinity" title="Unlimited Use">
            Buy once and use it forever. Sounds like A lifetime deal? Yes!
          </IconBox>
          <IconBox icon="vg-file" title="Extended License">
            Use extensive ways but for a price of standard license.
          </IconBox>
          <IconBox
            className="col-span-1 md:col-span-2 lg:col-span-1"
            icon="vg-circle-check"
            title="60-Day Refund"
          >
            <>
              Never ever worry while you checkout. Get a piece of mind, for{" "}
              <Link className="text-brand-primary underline" to="/refund">
                60 days!
              </Link>
            </>
          </IconBox>
        </div>
      </section>
      {!!(collection.OptionalHeadline && collection.OptionalDescription) && (
        <section className="md:mb-40 mb-24">
          <div>
            <SecondaryHeader
              title={collection.OptionalHeadline}
              description={collection.OptionalDescription}
            />
          </div>
        </section>
      )}
      {!!collection.FAQs?.data?.length &&
        collection.FAQs?.data?.map((item) => (
          <section className="mb-40" key={item.id}>
            <div>
              <SecondaryHeader title={item.attributes.Title} />
            </div>
            <div className="max-w-3xl m-auto">
              {item.attributes.Answers.map((answer) => (
                <FlipCard key={answer.id} title={answer.Title}>
                  {answer.Description}
                </FlipCard>
              ))}
            </div>
          </section>
        ))}
      <section className="lg:mb-24 mb-8">
        <Subscription />
      </section>
    </Layout>
  );
}

CollectionPage.propTypes = {
  pageContext: PropTypes.object,
  location: PropTypes.object,
};

export default CollectionPage;
